import * as React from 'react'

import { useTheme } from '@thg-commerce/gravity-elements/theme'

import { VisuallyHidden } from '../VisuallyHidden'

import {
  LeftButton,
  QuantityInput,
  QuantityInputLabel,
  QuantityLabel,
  QuantitySelectorContainer,
  RightButton,
  StyledButton,
  StyledMinus,
  StyledPlus,
  StyledSvgIcon,
} from './styles'

export interface QuantitySelectorProps {
  i18nText: {
    increaseButtonLabel: string
    decreaseButtonLabel: string
    quantityLabel: string
  }
  minValue?: number
  maxValue: number
  editable?: boolean
  quantity: number
  className?: string
  stepValue?: number
  quantityChangedCallback?: (quantity: number | null) => void
  showInnerBorders?: boolean
  disabled?: boolean
  alternateWidth?: boolean
}

export const QuantitySelector = ({
  minValue = 1,
  maxValue,
  quantityChangedCallback,
  quantity,
  i18nText,
  editable,
  className,
  stepValue,
  showInnerBorders,
  disabled,
  alternateWidth,
}: QuantitySelectorProps) => {
  const quantityInputRef = React.useRef<HTMLInputElement>(null)

  const maxValueReached = minValue > maxValue
  const theme = useTheme()

  if (process.env.NODE_ENV !== 'production' && maxValue < minValue) {
    console.warn(
      `QuantitySelector: cannot set the maxValue of ${maxValue} to be less than the minValue of ${minValue}`,
    )
  }

  const DecreaseQuantityButton = showInnerBorders ? StyledButton : LeftButton
  const IncreaseQuantityButton = showInnerBorders ? StyledButton : RightButton

  return (
    <QuantitySelectorContainer
      className={className}
      quantityAsLabel={editable}
      alternateWidth={alternateWidth}
    >
      <React.Fragment>
        <DecreaseQuantityButton
          data-testid="quantity-selector-decrement-button"
          aria-label={i18nText.decreaseButtonLabel}
          disabled={quantity === minValue}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()

            !disabled &&
              quantityChangedCallback &&
              quantityChangedCallback(
                Math.max((quantity || minValue) - (stepValue || 1), minValue),
              )
          }}
        >
          {theme.elements.quantitySelector.icon.minus.svgPath ? (
            <StyledSvgIcon
              xmlns="http://www.w3.org/2000/svg"
              viewBox={theme.elements.quantitySelector.icon.viewBox}
              width={theme.elements.quantitySelector.icon.width}
              height={theme.elements.quantitySelector.icon.height}
              isDisabled={disabled || quantity === minValue}
            >
              <path
                d={theme.elements.quantitySelector.icon.minus.svgPath}
                fillRule="evenodd"
              />
            </StyledSvgIcon>
          ) : (
            <StyledMinus
              isDisabled={disabled || quantity === minValue}
              fill={
                disabled || quantity === minValue
                  ? theme.colors.palette.greys.light
                  : theme.colors.palette.greys.darker
              }
            />
          )}
        </DecreaseQuantityButton>
        {editable ? (
          <QuantityInputLabel alternateWidth={alternateWidth}>
            <VisuallyHidden text={i18nText.quantityLabel} />
            <QuantityInput
              data-testid="quantity-selector-label"
              ref={quantityInputRef}
              value={quantity === null ? '' : quantity}
              type="text"
              pattern="[0-9]*"
              inputMode="numeric"
              aria-live="assertive"
              aria-atomic="true"
              disabled={maxValueReached}
              onChange={(event) => {
                const value = parseInt(event.currentTarget.value, 10)

                quantityChangedCallback &&
                  quantityChangedCallback(
                    isNaN(value)
                      ? null
                      : Math.min(Math.max(value, minValue), maxValue),
                  )
              }}
              onBlur={() => {
                if (quantity === null || isNaN(quantity)) {
                  quantityChangedCallback && quantityChangedCallback(minValue)
                }
              }}
            />
          </QuantityInputLabel>
        ) : (
          <QuantityLabel
            aria-live="assertive"
            aria-atomic="true"
            data-testid="quantity-selector-value-label"
          >
            <VisuallyHidden text={i18nText.quantityLabel} />
            {quantity}
          </QuantityLabel>
        )}
        <IncreaseQuantityButton
          data-testid="quantity-selector-increment-button"
          aria-label={i18nText.increaseButtonLabel}
          disabled={quantity === maxValue}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()

            !disabled &&
              quantityChangedCallback &&
              quantityChangedCallback(
                Math.min(
                  (quantity >= 0 ? quantity : maxValue) + (stepValue || 1),
                  maxValue,
                ),
              )
          }}
        >
          {theme.elements.quantitySelector.icon.plus.svgPath ? (
            <StyledSvgIcon
              xmlns="http://www.w3.org/2000/svg"
              viewBox={theme.elements.quantitySelector.icon.viewBox}
              width={theme.elements.quantitySelector.icon.width}
              height={theme.elements.quantitySelector.icon.height}
              isDisabled={disabled || quantity === maxValue}
            >
              <path
                d={theme.elements.quantitySelector.icon.plus.svgPath}
                fillRule="evenodd"
              />
            </StyledSvgIcon>
          ) : (
            <StyledPlus
              isDisabled={disabled || quantity === maxValue}
              fill={
                disabled || quantity === maxValue
                  ? theme.colors.palette.greys.light
                  : theme.colors.palette.greys.darker
              }
            />
          )}
        </IncreaseQuantityButton>
      </React.Fragment>
    </QuantitySelectorContainer>
  )
}
